import React, { useState, useEffect } from "react"
import { fetchPageContents } from "../components/utilities"
import Layout from "../components/layout"
import Seo from "../components/seo"

const AttributionScholarship = () => {

  const [pageContents, setPageContents] = useState("");

  useEffect(() => {
    fetchPageContents("scholarship")
      .then(text => setPageContents(text));
  },[]);

  return (
    <Layout>
      <Seo title="Attribution Scholarship" />
      <div
        dangerouslySetInnerHTML={{__html: pageContents}}>
      </div>
    </Layout>
  )
}

export default AttributionScholarship
